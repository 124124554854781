import React from "react";
import Hls from 'hls.js';
import PropTypes from "prop-types";

class HlsPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.hls = null;
    this.state = {
      playerId: Date.now()
    };
    this.video = React.createRef()
  }

  componentDidUpdate() {
    this._initPlayer();
  }

  componentDidMount() {
    this._initPlayer();
  }

  _initPlayer() {
    if (this.hls) {
      this.hls.destroy();
    }

    let {url, autoplay, hlsConfig} = this.props;
    let hls = new Hls(hlsConfig);
    console.log('000000')
    hls.loadSource(url);
    hls.attachMedia(this.video.current);
    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      console.log('000000-2222', autoplay, this.video.current)
      if (autoplay) {
        this.video.current.play();
      }
    });
    console.log('000000-11111')
    this.hls = hls;
  }

  render() {
    let {playerId} = this.state;
    const {controls, width, height, poster, videoProps} = this.props;

    return (
        <div key={playerId} className="player-area">
          <video ref={this.video}
                 className="hls-player"
                 id={`react-hls-${playerId}`}
                 controls={controls}
                 width={width}
                 height={height}
                 poster={poster}
                 muted={true}
                 {...videoProps}/>
        </div>
    )
  }
}

HlsPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
  hlsConfig: PropTypes.object,
  hlsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.any})
  ]),
  controls: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  poster: PropTypes.string,
  videoProps: PropTypes.object
}

HlsPlayer.defaultProps = {
  autoplay: false,
  hlsConfig: {},
  controls: true,
  width: 500,
  height: 375
}

export default HlsPlayer;
// Import React FilePond
// import 'react-toastify/dist/ReactToastify.css';
import './styles/toastify.scss'
import HlsPlayer from "./HlsPlayer";
import {toast, ToastContainer} from "react-toastify";
import React from "react";
import Hls from 'hls.js'

const TOASTS_LIMIT = 3;
const ToastContainerStyle = {
  width: '960px',
}
const ToastStyle = {

}
class LivePlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toasts: []
    }
    this.hlsRef = React.createRef();
  }

  componentDidMount() {
    this.ddd1()
  }

  componentDidUpdate() {
    this.ddd1()
  }

  ddd1() {
    this.hlsRef.current.hls.on(Hls.Events.FRAG_CHANGED, (e, frag) => {
      console.log(2222, frag, frag.frag.title)
      let info = JSON.parse(frag.frag.title)

      this.state.toasts.push(
          toast(<a href={info.link} target='_blank'>{info.title}</a>))
      console.log('IN', this.state.toasts.length)

    })
    toast.onChange(() => {
      console.log('change', arguments)
      while (this.state.toasts.length > TOASTS_LIMIT) {
        let toastId = this.state.toasts.shift();
        console.log(toastId);
        toast.dismiss(toastId);
        console.log('OUT', this.state.toasts.length)
      }
    })
  }

  render() {
    const {apiHost} = this.props
    const locationPath = window.location.pathname
    const playlist = locationPath.startsWith('/playlists/')
        ? locationPath.slice(0, locationPath.lastIndexOf('/'))
        : ''
    return (<div>
          <HlsPlayer
              url={`${apiHost}${playlist}/master.m3u8`}
              autoplay={true}
              controls={true}
              width={960}
              height={540}
              ref={this.hlsRef}
              hlsConfig={{
                debug: false,
                maxLoadingDelay: 4,
                minAutoBitrate: 0,
                lowLatencyMode: true,
                stretchShortVideoTrack: true,
              }}
          />
          <ToastContainer
              position="bottom-left"
              hideProgressBar={false}
              // className={ToastContainerStyle}
              // toastClassName={}
              newestOnTop={true}
              closeButton={false}
              closeOnClick={false}
              autoClose={false}
              draggable={false}
          />
        </div>
    )
  }
}

export default LivePlayer;
import './App.css';
import React from "react";
import LivePlayer from './LivePlayer'
import CustomVideoForm from './CustomVideoForm'

import {Route, BrowserRouter as Router, Switch} from "react-router-dom";

function App() {
  console.log(process.env, window.location)

  const apiHost = process.env.REACT_APP_API_HOST || ''
  const uploadHost = process.env.REACT_APP_UPLOAD_HOST
      || process.env.REACT_APP_API_HOST || ''
  console.log(uploadHost)

  return (
      <div className="App">
        <header className="App-header">
          <Router>
            <Switch>
              <Route path="/upload">
                <CustomVideoForm apiHost={apiHost} uploadHost={uploadHost}/>
              </Route>
              <Route path="/">
                <LivePlayer apiHost={apiHost}/>
              </Route>
            </Switch>
          </Router>

        </header>

      </div>
  );
}

export default App;

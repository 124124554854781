import React from "react";
import {v4 as uuidv4} from 'uuid';
import {FilePond} from "react-filepond";
import axios from "axios";
import PropTypes from "prop-types";
import crypto from 'crypto'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

const sha256 = crypto.createHash('sha256')

class CustomVideoForm extends React.Component {
  constructor(props) {
    super(props);
    this.form_id = uuidv4()
    this.state = {
      files: []
    }
    this.uploadFile = this.uploadFile.bind(this);
  }

  setFiles(files) {
    console.log(this, files)
    this.setState({files: files})
  }

  uploadFile(fieldName, file, metadata, load, error, progress, abort,
      transfer, options) {
    const {uploadHost} = this.props
    const formData = new FormData();
    formData.append(fieldName, file, file.name);
    console.log(file)
    const hash = sha256.update(`${file.name}_${Date.now()}`).digest(
        'hex').slice(0, 6)
    const uploadFileName = `${this.form_id}/${hash}_${file.name}`
    // get upload url
    let cancel = axios.CancelToken.source();
    axios.get(`${uploadHost}/upload?a=upload&file=${uploadFileName}`, {
      cancelToken: cancel.token
    })
    .then(res => {
      cancel = axios.CancelToken.source();
      axios.post(res.data.url, formData, {
        cancelToken: cancel.token
      }).then(() => {
        console.log('upload OK')
        load()
      }).catch(() => {
        if (axios.isCancel(cancel)) {
          console.log('upload canceled')
        } else {
          console.log('upload NOT_OK')
          error()
        }
      })
    }).catch(() => {
      console.log('signed url NOT_OK')
      error()
    })
    return {
      abort: () => {
        cancel.cancel()
        abort()
      }
    }
  }

  render() {
    return <form style={{width: "400px"}}>
      <div>
        <input type="hidden" name="id" value={this.form_id}/>
        <FilePond
            files={this.files}
            allowMultiple={true}
            instantUpload={false}
            server={{process: this.uploadFile}}
        >
        </FilePond>
      </div>
    </form>
  }
}

CustomVideoForm.propTypes = {
  apiHost: PropTypes.string.isRequired,
  uploadHost: PropTypes.string.isRequired,
}

export default CustomVideoForm;
